import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { class: "nested-dropdown__input-container" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 1,
  class: "nested-dropdown__placeholder"
}
const _hoisted_4 = { class: "nested-dropdown__input-actions" }
const _hoisted_5 = { class: "nested-dropdown__label" }

import AppButtonWrapper from '@/components/app/AppButtonWrapper/AppButtonWrapper.vue';
import { NestedOption } from '@/shared/types/components';
import IconArrowDown from '@/assets/icons/icon-arrow-down.vue';
import { GRAY_800 } from '@/shared/constants/colors';
import { ref } from 'vue';
import { symRoundedKeyboardArrowDown } from '@quasar/extras/material-symbols-rounded';
import { BaseFieldSet } from '@vaibe-github-enterprise/kd-kse-fe-commons';
import IconClear from '@/assets/icons/icon-clear.vue';
import AppSelectGrouped from '../AppSelectGrouped/AppSelectGrouped.vue';
import AppDropdownLabel from '../AppDropdownLabel/AppDropdownLabel.vue';
import AppIcon from '../AppIcon/AppIcon.vue';

interface Props {
  options: NestedOption[];
  label: string;
  placeholder?: string;
  formId: string;
  controlId: string;
  fieldsetLabel?: string;
  hideBottomSpace?: boolean;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'AppNestedDropdown',
  props: /*@__PURE__*/_mergeModels({
    options: {},
    label: {},
    placeholder: { default: '' },
    formId: {},
    controlId: {},
    fieldsetLabel: { default: '' },
    hideBottomSpace: { type: Boolean, default: false }
  }, {
    "modelValue": { required: true },
    "modelModifiers": {},
    "error": {},
    "errorModifiers": {},
  }),
  emits: ["update:modelValue", "update:error"],
  setup(__props: any) {



const appSelectedGroup = ref();

const model = _useModel<string[]>(__props, "modelValue");
const error = _useModel<string>(__props, 'error');

const menuModel = ref(false);

function showDropdown(): void {
  if (appSelectedGroup.value) {
    appSelectedGroup.value.showPopup();
  }
}

function clearModel(event: Event): void {
  model.value = [];
  event.stopPropagation();
}

return (_ctx: any,_cache: any) => {
  const _component_q_menu = _resolveComponent("q-menu")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!

  return (_openBlock(), _createBlock(AppButtonWrapper, null, {
    default: _withCtx(() => [
      _createVNode(_unref(BaseFieldSet), {
        error: error.value,
        "onUpdate:error": _cache[3] || (_cache[3] = ($event: any) => ((error).value = $event)),
        "form-id": _ctx.formId,
        "control-id": _ctx.controlId,
        label: _ctx.fieldsetLabel
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", {
              class: _normalizeClass([
            'nested-dropdown__input',
            { 'nested-dropdown__input--error': error.value },
          ])
            }, [
              (_ctx.label)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.label), 1))
                : (_ctx.placeholder)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.placeholder), 1))
                  : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_4, [
                (model.value.length)
                  ? (_openBlock(), _createBlock(AppButtonWrapper, {
                      key: 0,
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (clearModel($event)))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(IconClear)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _createVNode(AppIcon, {
                  name: _unref(symRoundedKeyboardArrowDown),
                  class: _normalizeClass([
                'nested-dropdown__input-arrow',
                { 'nested-dropdown__input-arrow--open': menuModel.value },
              ])
                }, null, 8, ["name", "class"])
              ])
            ], 2),
            _createVNode(_component_q_menu, {
              modelValue: menuModel.value,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((menuModel).value = $event)),
              class: "nested-dropdown__menu"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_list, {
                  dense: "",
                  style: {"min-width":"100px"}
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
                      return (_openBlock(), _createBlock(_component_q_item, {
                        key: index,
                        class: "nested-dropdown__item",
                        clickable: ""
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_5, [
                            _createVNode(AppDropdownLabel, {
                              label: option.label
                            }, null, 8, ["label"]),
                            _createVNode(IconArrowDown, {
                              class: "nested-dropdown__arrow",
                              color: _unref(GRAY_800)
                            }, null, 8, ["color"])
                          ]),
                          _createVNode(_component_q_menu, {
                            anchor: "top end",
                            self: "top start",
                            onShow: showDropdown
                          }, {
                            default: _withCtx(() => [
                              _createVNode(AppSelectGrouped, {
                                ref_for: true,
                                ref: 
                      (el) => {
                        appSelectedGroup.value = el;
                      }
                    ,
                                modelValue: model.value,
                                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((model).value = $event)),
                                options: option.options,
                                "popup-content-class": "app-nested-dropdown",
                                clearable: "",
                                size: "M",
                                "bottom-slots": "",
                                "local-search": "",
                                "map-options": "",
                                "show-search": "",
                                "menu-anchor": "top start",
                                "menu-self": "top start",
                                width: "300px",
                                class: "nested-dropdown__select",
                                "hide-bottom-space": _ctx.hideBottomSpace
                              }, null, 8, ["modelValue", "options", "hide-bottom-space"])
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024))
                    }), 128))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["modelValue"])
          ])
        ]),
        _: 1
      }, 8, ["error", "form-id", "control-id", "label"])
    ]),
    _: 1
  }))
}
}

})