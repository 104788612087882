import { useCssVars as _useCssVars, unref as _unref, defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src", "data-selected"]

import { computed } from 'vue';

interface Props {
  size?: string;
  selected?: boolean;
  imageSrc: string | ArrayBuffer | undefined;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AppImageSelectorImage',
  props: {
    size: { default: '100%' },
    selected: { type: Boolean, default: false },
    imageSrc: {}
  },
  setup(__props: any) {

_useCssVars(_ctx => ({
  "3586dc9c": (_ctx.size)
}))

const props = __props;

const src = computed<string | undefined>(() => props.imageSrc?.toString());

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("img", {
    src: src.value,
    draggable: "false",
    alt: "Image selector image",
    class: "app-image-selector-image",
    "data-selected": _ctx.selected
  }, null, 8, _hoisted_1))
}
}

})