<script setup lang="ts">
import { computed } from 'vue';

interface Props {
  size?: string;
  selected?: boolean;
  imageSrc: string | ArrayBuffer | undefined;
}

const props = withDefaults(defineProps<Props>(), {
  size: '100%',
  selected: false,
});

const src = computed<string | undefined>(() => props.imageSrc?.toString());
</script>

<template>
  <img
    :src="src"
    draggable="false"
    alt="Image selector image"
    class="app-image-selector-image"
    :data-selected="selected"
  />
</template>

<style scoped lang="scss">
.app-image-selector-image {
  aspect-ratio: 1;
  border-radius: 8px;
  object-fit: contain;
  width: v-bind(size);
  height: v-bind(size);
  transition: border 0.2s ease-in-out;
}

.app-image-selector-image[data-selected='true'] {
  border: 4px solid $primary-color;
}
</style>
