<script setup lang="ts">
import AppButtonWrapper from '@/components/app/AppButtonWrapper/AppButtonWrapper.vue';
import { NestedOption } from '@/shared/types/components';
import IconArrowDown from '@/assets/icons/icon-arrow-down.vue';
import { GRAY_800 } from '@/shared/constants/colors';
import { ref } from 'vue';
import { symRoundedKeyboardArrowDown } from '@quasar/extras/material-symbols-rounded';
import { BaseFieldSet } from '@vaibe-github-enterprise/kd-kse-fe-commons';
import IconClear from '@/assets/icons/icon-clear.vue';
import AppSelectGrouped from '../AppSelectGrouped/AppSelectGrouped.vue';
import AppDropdownLabel from '../AppDropdownLabel/AppDropdownLabel.vue';
import AppIcon from '../AppIcon/AppIcon.vue';

interface Props {
  options: NestedOption[];
  label: string;
  placeholder?: string;
  formId: string;
  controlId: string;
  fieldsetLabel?: string;
  hideBottomSpace?: boolean;
}
withDefaults(defineProps<Props>(), {
  placeholder: '',
  fieldsetLabel: '',
  hideBottomSpace: false,
});

const appSelectedGroup = ref();

const model = defineModel<string[]>({ required: true });
const error = defineModel<string>('error');

const menuModel = ref(false);

function showDropdown(): void {
  if (appSelectedGroup.value) {
    appSelectedGroup.value.showPopup();
  }
}

function clearModel(event: Event): void {
  model.value = [];
  event.stopPropagation();
}
</script>

<template>
  <AppButtonWrapper>
    <BaseFieldSet
      v-model:error="error"
      :form-id="formId"
      :control-id="controlId"
      :label="fieldsetLabel"
    >
      <div class="nested-dropdown__input-container">
        <div
          :class="[
            'nested-dropdown__input',
            { 'nested-dropdown__input--error': error },
          ]"
        >
          <div v-if="label">{{ label }}</div>
          <div v-else-if="placeholder" class="nested-dropdown__placeholder">
            {{ placeholder }}
          </div>
          <div class="nested-dropdown__input-actions">
            <AppButtonWrapper v-if="model.length" @click="clearModel($event)">
              <IconClear />
            </AppButtonWrapper>
            <AppIcon
              :name="symRoundedKeyboardArrowDown"
              :class="[
                'nested-dropdown__input-arrow',
                { 'nested-dropdown__input-arrow--open': menuModel },
              ]"
            />
          </div>
        </div>

        <q-menu v-model="menuModel" class="nested-dropdown__menu">
          <q-list dense style="min-width: 100px">
            <template v-for="(option, index) in options" :key="index">
              <q-item class="nested-dropdown__item" clickable>
                <div class="nested-dropdown__label">
                  <AppDropdownLabel :label="option.label" />
                  <IconArrowDown
                    class="nested-dropdown__arrow"
                    :color="GRAY_800"
                  />
                </div>

                <q-menu anchor="top end" self="top start" @show="showDropdown">
                  <AppSelectGrouped
                    :ref="
                      (el) => {
                        appSelectedGroup = el;
                      }
                    "
                    v-model="model"
                    :options="option.options"
                    popup-content-class="app-nested-dropdown"
                    clearable
                    size="M"
                    bottom-slots
                    local-search
                    map-options
                    show-search
                    menu-anchor="top start"
                    menu-self="top start"
                    width="300px"
                    class="nested-dropdown__select"
                    :hide-bottom-space="hideBottomSpace"
                  />
                </q-menu>
              </q-item>
            </template>
          </q-list>
        </q-menu>
      </div>
    </BaseFieldSet>
  </AppButtonWrapper>
</template>

<style scoped lang="scss">
.nested-dropdown__input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 16px;
  border-radius: 4px;
  border: 1px solid $gray-400;
  background-color: $white;
  line-height: 1.5;
  padding: 5px 12px;
  min-height: 48px;
  font-weight: 400;
  font-size: 14px;
}

.nested-dropdown__input--error {
  border-color: $red-500;
}

.nested-dropdown__input:hover {
  border: 1px solid var(--primary-color);
}

.nested-dropdown__input-arrow {
  font-size: 24px;
  transition: transform 0.3s ease-in-out;
}

.nested-dropdown__input-arrow--open {
  transform: rotate(180deg);
}

.nested-dropdown__item :deep(.app-label__label) {
  min-width: 80px;
}

.nested-dropdown__item :deep(.q-focus-helper) {
  display: none;
}

.nested-dropdown__input-container {
  width: 100%;
}

.nested-dropdown__label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  flex-grow: 1;
}

.nested-dropdown__label:hover {
  background-color: $gray-50;
}

.nested-dropdown__label :deep(.app-label__label:hover) {
  background-color: unset;
}

.nested-dropdown__arrow {
  padding: 8px;
  transform: rotate(-90deg);
  box-sizing: content-box;
}

.nested-dropdown__placeholder {
  color: $gray-500;
}

.nested-dropdown__input-actions {
  display: flex;
  align-items: center;
  gap: 16px;
}
</style>

<style lang="scss">
.q-menu:has(.nested-dropdown__select) {
  visibility: hidden !important;
}

.nested-dropdown__menu {
  padding: 8px;
}

.q-list--dense > .q-item.nested-dropdown__item {
  padding: 0;
}
</style>
