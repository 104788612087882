import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "app-timeline-config" }
const _hoisted_2 = { class: "app-timeline-config__content" }
const _hoisted_3 = {
  key: 0,
  class: "app-timeline-config__title"
}
const _hoisted_4 = { class: "app-timeline-config__accelerators" }
const _hoisted_5 = { class: "app-timeline-config__content" }
const _hoisted_6 = {
  key: 0,
  class: "app-timeline-config__title"
}
const _hoisted_7 = { class: "app-timeline-config__time" }
const _hoisted_8 = {
  key: 0,
  class: "app-timeline-config__time-intro"
}
const _hoisted_9 = { class: "app-timeline-config__time-title" }
const _hoisted_10 = { class: "app-timeline-config__time-selectors" }
const _hoisted_11 = { class: "app-timeline-config__time-selectors" }

import { computed, reactive, watch } from 'vue';
import {
  AppButtonToggleOption,
  DatePeriod,
  DurationErrorModel,
} from '@/shared/types/generic';
import { useI18n } from 'vue-i18n';
import {
  ChallengeAudienceStaticReference,
  TypeChallenge,
} from '@/shared/types/challenges';
import {
  WizardDurationModel,
  WizardDurationPreset,
  ChallengeFormat,
} from '@/shared/types/wizard';
import { isEmpty } from '@/shared/helpers/validators/validators';
import useProfileStore from '@/store/profile/useProfileStore';
import useUserStore from '@/store/user/useUserStore';
import { RequestUsersTimezones } from '@/shared/types/user';
import AppResultsRelease from '../AppResultsRelease/AppResultsRelease.vue';
import AppButtonToggle from '../AppButtonToggle/AppButtonToggle.vue';
import AppSequence from '../AppSequence/AppSequence.vue';
import AppDatePicker from '../AppDatePicker/AppDatePicker.vue';
import AppTimePicker from '../AppTimePicker/AppTimePicker.vue';
import AppLabel from '../AppLabel/AppLabel.vue';

interface Props {
  errors: DurationErrorModel;
  showText?: boolean;
  challengeAudience: ChallengeAudienceStaticReference;
  challengeParticipants: string[];
  challengeFormat: ChallengeFormat;
  fillWidth?: boolean;
}

interface State {
  resultsForecast: number | undefined;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AppTimelineConfig',
  props: /*@__PURE__*/_mergeModels({
    errors: {},
    showText: { type: Boolean, default: true },
    challengeAudience: {},
    challengeParticipants: {},
    challengeFormat: {},
    fillWidth: { type: Boolean }
  }, {
    "modelError": {
  required: true,
},
    "modelErrorModifiers": {},
    "model": {
  required: true,
},
    "modelModifiers": {},
  }),
  emits: ["update:modelError", "update:model"],
  setup(__props: any) {

const { t } = useI18n();
const profileStore = useProfileStore();
const userStore = useUserStore();

const state = reactive<State>({
  resultsForecast: undefined,
});

const props = __props;

const modelError = _useModel<DurationErrorModel>(__props, 'modelError');

const model = _useModel<WizardDurationModel>(__props, 'model');

const presetOptions = Object.values(WizardDurationPreset).map((preset) => ({
  value: preset,
  label: t(`enums.duration.${preset}`),
})) as AppButtonToggleOption[];

const isEndDateDisabled = computed<boolean>(() => {
  return model.value.preset !== WizardDurationPreset.CUSTOM;
});

function applyPreset(daysAfterStart: DatePeriod): void {
  const endDateStamp = daysAfterStart;

  model.value = {
    ...model.value,
    endDate: new Date(model.value.startDate.getTime() + endDateStamp),
  };
}

function setStartDate(): void {
  model.value = {
    ...model.value,
    startDate: new Date(
      Date.now() +
        (profileStore.userProfile?.userConfig.enrollmentTime || 0) +
        DatePeriod.MINUTE * 5,
    ),
  };
}

function updateDates(resetStartDate: boolean): void {
  switch (model.value.preset) {
    case WizardDurationPreset.DAY:
      if (resetStartDate) setStartDate();
      applyPreset(DatePeriod.DAY);
      break;
    case WizardDurationPreset.WEEK:
      if (resetStartDate) setStartDate();
      applyPreset(DatePeriod.WEEK);
      break;
    case WizardDurationPreset.MONTH:
      if (resetStartDate) setStartDate();
      applyPreset(DatePeriod.MONTH);
      break;
    default:
      break;
  }
}

async function getResultsForecast() {
  const typeChallenge =
    props.challengeAudience === ChallengeAudienceStaticReference.INDIVIDUAL
      ? TypeChallenge.INDIVIDUAL
      : TypeChallenge.TEAM;

  const request: RequestUsersTimezones = {
    audience: typeChallenge,
    startDate: model.value.startDate.getTime(),
    endDate: model.value.endDate.getTime(),
  };

  const participantsProperty =
    props.challengeAudience === ChallengeAudienceStaticReference.INDIVIDUAL
      ? 'workerReferences'
      : 'teamReferences';

  request[participantsProperty] = props.challengeParticipants as string[];

  const date = await userStore.getResultForecastUsers(request);

  state.resultsForecast = date ?? 0;
}

const endTooltip = computed<string>(() => {
  if (
    props.challengeFormat === ChallengeFormat.RACE ||
    props.challengeFormat === ChallengeFormat.TEAM_RACE
  ) {
    return t('games.challengeWizard.steps.timeline.tooltip');
  }
  return '';
});

async function checkForMultipleTimezones(
  participants: string[],
): Promise<void> {
  let request: RequestUsersTimezones = {};
  if (props.challengeAudience === ChallengeAudienceStaticReference.INDIVIDUAL) {
    request = {
      workerReferences: participants,
      audience: TypeChallenge.INDIVIDUAL,
    };
  } else {
    request = {
      teamReferences: participants,
      audience: TypeChallenge.TEAM,
    };
  }

  await userStore.getTimezoneUsersParticipants(request);
}

watch(
  () => model.value.preset,
  (newPreset: WizardDurationPreset, oldPreset: WizardDurationPreset) => {
    if (newPreset !== oldPreset && oldPreset) {
      updateDates(true);
    }
  },
);

watch(
  () => model.value.startDate,
  (newStartDate: Date, oldStartDate: Date) => {
    if (newStartDate !== oldStartDate && oldStartDate) {
      updateDates(false);
    }
  },
);

watch(
  () => model.value.endDate,
  (newEndDate: Date, oldEndDate: Date) => {
    const difference = Math.abs(
      (oldEndDate?.getTime() || 0) - (newEndDate?.getTime() || 0),
    );
    if (
      (difference > 100000 || !!oldEndDate) &&
      userStore.hasUsersDifferentTimezone
    ) {
      getResultsForecast();
    }
  },
);

watch(
  () => props.challengeParticipants,
  async (newValue) => {
    if (!isEmpty(newValue)) {
      if (
        (props.challengeFormat === ChallengeFormat.TEAM_COMPETITION ||
          props.challengeFormat === ChallengeFormat.TEAM_RACE) &&
        props.challengeParticipants.length === 2 &&
        !(
          typeof props.challengeParticipants[0] === 'string' &&
          typeof props.challengeParticipants[1] === 'string'
        )
      ) {
        userStore.hasUsersDifferentTimezone = false;
      } else {
        await checkForMultipleTimezones(newValue as string[]);
      }
      if (userStore.hasUsersDifferentTimezone) {
        getResultsForecast();
      }
    }
  },
  { immediate: true, deep: true },
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.showText)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_unref(t)('games.duration')), 1))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(AppButtonToggle, {
          modelValue: model.value.preset,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((model.value.preset) = $event)),
          class: _normalizeClass([
            'app-timeline-config__buttons',
            { 'app-timeline-config__buttons--fill-width': _ctx.fillWidth },
          ]),
          options: _unref(presetOptions),
          "isolated-buttons": ""
        }, null, 8, ["modelValue", "class", "options"])
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      (_ctx.showText)
        ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_unref(t)('games.dateTime')), 1))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_7, [
        (_ctx.showText)
          ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_unref(t)('games.challengeWizard.steps.timeline.timeIntro')), 1))
          : _createCommentVNode("", true),
        _createVNode(AppSequence, null, {
          default: _withCtx(() => [
            _createElementVNode("div", null, [
              _createElementVNode("span", _hoisted_9, _toDisplayString(_unref(t)('games.start')), 1),
              _createElementVNode("div", _hoisted_10, [
                _createVNode(AppDatePicker, {
                  modelValue: model.value.startDate,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((model.value.startDate) = $event)),
                  class: "app-duration__start-time",
                  error: props.errors.startDate
                }, null, 8, ["modelValue", "error"]),
                _createVNode(AppTimePicker, {
                  modelValue: model.value.startDate,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((model.value.startDate) = $event)),
                  error: modelError.value.startTime,
                  "onUpdate:error": _cache[3] || (_cache[3] = ($event: any) => ((modelError.value.startTime) = $event)),
                  class: "app-duration__start-time",
                  "align-icon": "prepend"
                }, null, 8, ["modelValue", "error"])
              ])
            ]),
            _createElementVNode("div", null, [
              _createVNode(AppLabel, {
                label: _unref(t)('games.end')
              }, _createSlots({ _: 2 }, [
                (endTooltip.value)
                  ? {
                      name: "information",
                      fn: _withCtx(() => [
                        _createTextVNode(_toDisplayString(endTooltip.value), 1)
                      ]),
                      key: "0"
                    }
                  : undefined
              ]), 1032, ["label"]),
              _createElementVNode("div", _hoisted_11, [
                _createVNode(AppDatePicker, {
                  modelValue: model.value.endDate,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((model.value.endDate) = $event)),
                  class: "app-duration__end-time",
                  disabled: isEndDateDisabled.value,
                  error: props.errors.endDate
                }, null, 8, ["modelValue", "disabled", "error"]),
                _createVNode(AppTimePicker, {
                  modelValue: model.value.endDate,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((model.value.endDate) = $event)),
                  error: modelError.value.endTime,
                  "onUpdate:error": _cache[6] || (_cache[6] = ($event: any) => ((modelError.value.endTime) = $event)),
                  class: "app-duration__end-time",
                  "align-icon": "prepend"
                }, null, 8, ["modelValue", "error"])
              ])
            ])
          ]),
          _: 1
        }),
        (_unref(userStore).hasUsersDifferentTimezone)
          ? (_openBlock(), _createBlock(AppResultsRelease, {
              key: 1,
              date: state.resultsForecast || 0,
              class: "app-timeline-config__timezones"
            }, null, 8, ["date"]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}
}

})