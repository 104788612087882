import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "app-sidebar-header-info" }
const _hoisted_2 = { class: "app-sidebar-header-info__item" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "app-sidebar-header-info__item-details" }
const _hoisted_5 = {
  key: 0,
  class: "app-sidebar-header-info__item-details--id"
}
const _hoisted_6 = { class: "app-sidebar-header-info__item-details--name" }

import AppSeparator from '@/components/app/AppSeparator/AppSeparator.vue';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { SidebarTitle } from '@/shared/types/generic';

interface Props {
  itemType: SidebarTitle;
  itemTitle?: string;
  itemUrl?: string;
  itemId?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AppSidebarHeaderInfo',
  props: {
    itemType: {},
    itemTitle: { default: '' },
    itemUrl: { default: '' },
    itemId: { default: '' }
  },
  setup(__props: any) {

const { t } = useI18n();

const props = __props;

const id = computed<string>(() => {
  return props.itemType === SidebarTitle.METRIC
    ? t('common.sidebar.tid', [props.itemId])
    : t('common.sidebar.id', [props.itemId]);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "illustration", {}, () => [
        (_ctx.itemUrl)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              class: _normalizeClass([
            'app-sidebar-header-info__item-illustration',
            {
              'app-sidebar-header-info__item-illustration--round':
                _ctx.itemType === _unref(SidebarTitle).USER,
            },
          ]),
              src: props.itemUrl,
              alt: "item cover image"
            }, null, 10, _hoisted_3))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_4, [
        (_ctx.itemId)
          ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(id.value), 1))
          : _createCommentVNode("", true),
        _createElementVNode("b", _hoisted_6, _toDisplayString(_ctx.itemTitle), 1),
        _renderSlot(_ctx.$slots, "details")
      ])
    ]),
    _createVNode(AppSeparator)
  ]))
}
}

})