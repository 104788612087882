import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, mergeProps as _mergeProps, createSlots as _createSlots } from "vue"

const _hoisted_1 = {
  key: "placeholder",
  class: "app-select__placeholder",
  "data-testid": "app-select-placeholder"
}
const _hoisted_2 = {
  key: "filterLabel",
  class: "app-select__label",
  "data-testid": "app-select-filter-label"
}
const _hoisted_3 = {
  key: 1,
  class: "app-select__label",
  "data-testid": "app-select-option-label"
}
const _hoisted_4 = {
  key: 0,
  class: "app-select__option__search-wrap",
  "data-testid": "app-select-option-search-wrap"
}
const _hoisted_5 = {
  key: 0,
  class: "app-select__option__search-wrap"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppClearIcon = _resolveComponent("AppClearIcon")!
  const _component_AppSearch = _resolveComponent("AppSearch")!
  const _component_q_checkbox = _resolveComponent("q-checkbox")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_AppSeparator = _resolveComponent("AppSeparator")!
  const _component_AppLoading = _resolveComponent("AppLoading")!
  const _component_AppErrorMessage = _resolveComponent("AppErrorMessage")!
  const _component_q_select = _resolveComponent("q-select")!

  return (_openBlock(), _createBlock(_component_q_select, _mergeProps(_ctx.$props, {
    ref: "qselect",
    modelValue: _ctx.model,
    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.model) = $event)),
    options: _ctx.filteredOptions,
    "dropdown-icon": _ctx.symRoundedKeyboardArrowDown,
    "input-debounce": _ctx.inputDebounce,
    style: _ctx.style,
    class: ['app-select', `app-select--${_ctx.size}`],
    "input-class": "app-select__input",
    "popup-content-class": `app-select__popup-content ${_ctx.popupContentClass}`,
    "options-dense": "",
    error: _ctx.isError,
    clearable: false,
    borderless: "",
    "no-error-icon": "",
    "data-testid": "app-select-input",
    onBlur: _ctx.handlerOnBlur,
    onPopupShow: _cache[7] || (_cache[7] = ($event: any) => (_ctx.handleSelectPopupShow(_ctx.$el, '--appSelectMaxWidth')))
  }), _createSlots({
    append: _withCtx(() => [
      (_ctx.$slots.append)
        ? _renderSlot(_ctx.$slots, "before-options", { key: 0 }, undefined, true)
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            (_ctx.showClearable)
              ? (_openBlock(), _createBlock(_component_AppClearIcon, {
                  key: 0,
                  onClick: _ctx.handleClear
                }, null, 8, ["onClick"]))
              : _createCommentVNode("", true)
          ], 64))
    ]),
    "before-options": _withCtx(() => [
      (_ctx.isSearchShown)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_AppSearch, {
              modelValue: _ctx.filter,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filter) = $event)),
              "select-search": ""
            }, null, 8, ["modelValue"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.$slots['before-options'])
        ? _renderSlot(_ctx.$slots, "before-options", { key: 1 }, undefined, true)
        : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
            (_ctx.showAllOption)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass([
            'app-select__options__select-all',
            { 'app-select__options__select-all--with-search': _ctx.isSearchShown },
          ]),
                  "data-testid": "app-select-options-select-all",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.handleSelectAll(false))),
                  onKeydown: _cache[4] || (_cache[4] = ($event: any) => (_ctx.handleSelectAll(false)))
                }, [
                  _createVNode(_component_q_item, {
                    class: _normalizeClass([_ctx.optionClass, { 'q-item--active': _ctx.allModel }])
                  }, {
                    default: _withCtx(() => [
                      (_ctx.showCheckbox)
                        ? (_openBlock(), _createBlock(_component_q_item_section, {
                            key: 0,
                            avatar: ""
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_q_checkbox, {
                                modelValue: _ctx.allModel,
                                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.allModel) = $event)),
                                "toggle-order": "ft",
                                "data-testid": "app-select-checkbox",
                                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleSelectAll(true)))
                              }, null, 8, ["modelValue"])
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      _createVNode(_component_q_item_section, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_q_item_label, { "data-testid": "app-select-item-label" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('common.filters.all')), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["class"]),
                  _createVNode(_component_AppSeparator, { class: "app-select__options__select-all__separator" })
                ], 34))
              : _createCommentVNode("", true)
          ], 64))
    ]),
    "no-option": _withCtx(() => [
      (_ctx.isSearchShown)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_AppSearch, {
              modelValue: _ctx.filter,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.filter) = $event)),
              "select-search": ""
            }, null, 8, ["modelValue"])
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_q_item, {
        class: _normalizeClass([[_ctx.optionClass], "q-item--dense app-select__option app-select__option--not-found"])
      }, {
        default: _withCtx(() => [
          (_ctx.isLoadingExternalSearch)
            ? (_openBlock(), _createBlock(_component_AppLoading, {
                key: 0,
                width: "460px"
              }))
            : _createCommentVNode("", true),
          (!_ctx.externalSearch || _ctx.isShowEmptyStateExternalSearch)
            ? (_openBlock(), _createBlock(_component_q_item_section, { key: 1 }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_item_label, { "data-testid": "app-select-item-label" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.filteredNoOptionLabel || _ctx.$t('common.filters.noOptionFound')), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["class"])
    ]),
    option: _withCtx(({ index, itemProps, opt, label, selected, toggleOption }) => [
      (_ctx.$slots.option)
        ? _renderSlot(_ctx.$slots, "option", {
            key: 0,
            index: index,
            itemProps: itemProps,
            opt: opt,
            label: label,
            selected: selected,
            toggleOption: toggleOption,
            dataTestid: "app-select-slot-option"
          }, undefined, true)
        : (_openBlock(), _createBlock(_component_q_item, _mergeProps({ key: 1 }, itemProps, {
            class: "app-select__option",
            disable: _ctx.disableOptions && !selected,
            "data-testid": "app-select-item",
            "onUpdate:modelValue": ($event: any) => (toggleOption(opt))
          }), {
            default: _withCtx(() => [
              (_ctx.showCheckbox)
                ? (_openBlock(), _createBlock(_component_q_item_section, {
                    key: 0,
                    avatar: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_checkbox, {
                        "model-value": selected,
                        "data-testid": "app-select-checkbox",
                        disable: _ctx.disableOptions && !selected,
                        "onUpdate:modelValue": ($event: any) => (toggleOption(opt))
                      }, null, 8, ["model-value", "disable", "onUpdate:modelValue"])
                    ]),
                    _: 2
                  }, 1024))
                : _createCommentVNode("", true),
              _createVNode(_component_q_item_section, {
                "onUpdate:modelValue": ($event: any) => (toggleOption(opt))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_item_label, { "data-testid": "app-select-item-label" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(label), 1)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1032, ["onUpdate:modelValue"])
            ]),
            _: 2
          }, 1040, ["disable", "onUpdate:modelValue"]))
    ]),
    _: 2
  }, [
    (_ctx.showPlaceholder)
      ? {
          name: "selected",
          fn: _withCtx(() => [
            _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.placeholder), 1)
          ]),
          key: "0"
        }
      : (_ctx.filterLabel || _ctx.multiple)
        ? {
            name: "selected",
            fn: _withCtx(() => [
              _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.filterLabel || _ctx.$t('common.filters.options', _ctx.model.length)), 1)
            ]),
            key: "1"
          }
        : {
            name: "selected-item",
            fn: _withCtx(({ opt }) => [
              (_ctx.$slots['selected-item'])
                ? _renderSlot(_ctx.$slots, "selected-item", {
                    key: 0,
                    opt: opt
                  }, undefined, true)
                : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(opt[_ctx.optionLabel] || opt), 1))
            ]),
            key: "2"
          },
    (!_ctx.hideBottomSpace)
      ? {
          name: "error",
          fn: _withCtx(() => [
            (_ctx.$slots.error)
              ? _renderSlot(_ctx.$slots, "error", { key: 0 }, undefined, true)
              : (_openBlock(), _createBlock(_component_AppErrorMessage, {
                  key: 1,
                  error: _ctx.error
                }, null, 8, ["error"]))
          ]),
          key: "3"
        }
      : undefined
  ]), 1040, ["modelValue", "options", "dropdown-icon", "input-debounce", "style", "class", "popup-content-class", "error", "onBlur"]))
}