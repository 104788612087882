import { useCssVars as _useCssVars, unref as _unref, defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

interface Props {
  borderRadius?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AppAvatar',
  props: {
    borderRadius: { default: '50%' }
  },
  setup(__props: any) {

_useCssVars(_ctx => ({
  "aeab3772": (_ctx.borderRadius)
}))



return (_ctx: any,_cache: any) => {
  const _component_q_avatar = _resolveComponent("q-avatar")!

  return (_openBlock(), _createBlock(_component_q_avatar, { class: "app-avatar" }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }))
}
}

})